import { X } from '@phosphor-icons/react';
import { useLocalStorageState } from 'ahooks';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirebaseAuth } from 'src/contexts/AuthContext/FirebaseAuthContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import Cookies from 'universal-cookie';
import RightHandSidebar from './RightHandSidebar';
import { Sidebar } from './Sidebar';

type LayoutProps = {
  children?: React.ReactNode;
};

const cookies = new Cookies(null, { path: '/' });

export const LoggedLayout = ({ children }: LayoutProps) => {
  const { isOpen } = useRightHandSidebar();
  const auth = useFirebaseAuth();
  const [isCollapsed] = useLocalStorageState<boolean>(
    '@control-plane/sidebar-collapsed',
    {
      defaultValue: false,
      listenStorageChange: true,
    },
  );
  const [isClosedAlphaVisible, setIsClosedAlphaVisible] =
    useLocalStorageState<boolean>('@control-plane/closed-alpha-visible', {
      defaultValue: false,
      listenStorageChange: true,
    });
  const { isOpen: isRightSidebarOpen } = useRightHandSidebar();
  cookies.set('userId', auth?.user?.uid);

  useEffect(() => {
    const user = auth?.user;
    if (user?.uid) {
      window.pylon = {
        chat_settings: {
          app_id: process.env.REACT_APP_PYLON_APP_ID,
          email: user.email,
          name: user.displayName,
          avatar_url: user.photoURL,
        },
      };
    }
  }, [auth?.user]);

  return (
    <div className="flex flex-col h-screen">
      {!isClosedAlphaVisible && (
        <div className={clsx('bg-primary text-white text-center py-2')}>
          This is a beta version of Control Plane. Please report{' '}
          <a href="mailto:support@fluents.ai" className="underline">
            here (support@fluents.ai)
          </a>{' '}
          any issues you find.{' '}
          <button
            onClick={() => setIsClosedAlphaVisible(true)}
            className={clsx(
              'text-white hover:text-gray-400 absolute mt-1 m-4',
              'focus:outline-none',
            )}
          >
            <X />
          </button>
        </div>
      )}

      <div className="flex flex-1 flex-row bg-gray-100">
        <Sidebar classNameUserMenu={clsx(!isClosedAlphaVisible && 'mb-6')} />

        <div className="w-full block">
          <div
            className={clsx(
              'grid w-full transition-all duration-300',
              isRightSidebarOpen
                ? 'grid-cols-[1fr_340px]'
                : 'grid-cols-[1fr_0]',
            )}
          >
            <div
              className={clsx(
                'transition-all duration-300 flex flex-1 overflow-y-auto',
                isCollapsed ? 'ml-20' : 'ml-64',
              )}
            >
              <div
                className={clsx(
                  'bg-white my-4 rounded-3xl w-full',
                  isRightSidebarOpen ? 'mr-0' : 'mr-4',
                  !isOpen ? 'rounded-r-3xl' : 'rounded-r-none',
                  'overflow-auto',
                )}
              >
                {children || <Outlet />}
              </div>
            </div>

            <RightHandSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};
