import {
  ArrowSquareOut,
  MagnifyingGlass,
  PhoneSlash,
  Robot,
} from '@phosphor-icons/react';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { AutoCompleteSelectBox } from 'src/components/AutoCompleteSelectBox';
import { Button } from 'src/components/Button';
import { CallDetails } from 'src/components/CallDetails';
import { CallRecordingButton } from 'src/components/CallRecordingButton';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { Heading } from 'src/components/Heading';
import { SelectBox } from 'src/components/SelectBox';
import Table from 'src/components/Table';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { useRightHandSidebar } from 'src/contexts/RightHandSidebarContext';
import { Agent } from 'src/interfaces/agent.interface';
import { Call, ComparisonFilter } from 'src/interfaces/call.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import {
  CallStatus,
  CallStatusKeys,
  filter1Month,
  filter24Hours,
  filter7Days,
  periodsOptions,
  statusesOptions,
} from 'src/stubs/calls.stub';
import { friendlyDateFormatter } from 'src/utils/date';
import { getFirstAndLastFourUUID, processPhoneNumber } from 'src/utils/number';
import { get } from '../api/requests';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Tooltip } from 'src/components/Tooltip';

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'agent', label: 'Agent', width: '8rem' },
  { key: 'agent_name', label: 'Agent Name', width: '8rem' },
  { key: 'recording_available', label: 'Recording', width: '15%' },
  { key: 'start_time', label: 'Created At', width: '15%' },
  { key: 'end_time', label: 'Duration', width: '15%' },
  { key: 'from_number', label: 'From', width: '15%', wrapValue: false },
  { key: 'to_number', label: 'To', width: '15%', wrapValue: false },
  { key: 'status', label: 'Status', width: '10%' },
  { key: 'actions', label: '', width: '10%', disableSorting: true },
];

const agentNameCache: Record<string, string> = {};

export const Calls = () => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const { openSidebar } = useRightHandSidebar();
  const envId = environment?.envId;

  const [agents, setAgents] = useState([]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [filters, setFilters] = useState({
    agent: 'All Agents',
    eventType: 'All Events',
    status: 'All Statuses',
    timePeriod: 'All Time',
    from_number: '',
    to_number: '',
  });

  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const agentsOptions = [
    { value: 'All Agents' },
    ...agents.map((agent: { id: string }) => ({
      value: agent.id,
      label: getFirstAndLastFourUUID(agent.id),
    })),
  ];

  const updateFilters = (newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, ...newFilters };
      setCurrentPage(1);
      return updatedFilters;
    });
  };

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const filtersCopy: Partial<
      typeof filters & {
        do_not_call_result: boolean;
        start_time: ComparisonFilter;
        end_time: ComparisonFilter;
      }
    > = {
      ...filters,
      ...(filters.agent !== 'All Agents'
        ? { agent: filters.agent }
        : { agent: undefined }),
      ...(filters.status !== 'All Statuses'
        ? { status: filters.status }
        : { status: undefined }),
      timePeriod: undefined,
    };

    if (filters.timePeriod) {
      const period = prepareFilterPeriod(filters.timePeriod);

      if (period.start_time) {
        filtersCopy.start_time = period.start_time;
      }

      if (period.end_time) {
        filtersCopy.end_time = period.end_time;
      }
    }

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      filters: JSON.stringify(filtersCopy),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/calls?${query.toString()}`, {
      envId,
    });

    const uniqueAgents: (string | null)[] = Array.from(
      new Set(data.items.map((call: Call) => call.agent)),
    );
    await Promise.all(
      uniqueAgents.map(async (uniqueAgent) => {
        if (!!uniqueAgent && !agentNameCache[uniqueAgent]) {
          try {
            const agent = await get(`/agents/${uniqueAgent}`, {
              envId,
            });
            agentNameCache[uniqueAgent] = agent.name;
          } catch {
            agentNameCache[uniqueAgent] = '(deleted)';
          }
        }
      }),
    );

    const calls = await Promise.all(
      data.items.map(async (call: Call) => {
        const startDate = call.start_time
          ? new Date(Number(call.start_time) * 1000)
          : null;
        const endDate = call.end_time
          ? new Date(Number(call.end_time) * 1000)
          : null;

        return {
          ...call,
          id: <CopyableField value={call.id} notification={notification} />,
          agent: (
            <CopyableField value={call.agent} notification={notification} />
          ),
          agent_name: agentNameCache[call.agent],
          recording_available: call.recording_available ? (
            <CallRecordingButton
              callId={call.id}
              envId={envId}
              recordingAvailable={call.recording_available}
            />
          ) : (
            '-'
          ),
          start_time: friendlyDateFormatter(startDate),
          end_time:
            startDate && endDate
              ? formatDuration(
                  intervalToDuration({
                    start: startDate,
                    end: endDate,
                  }),
                  { delimiter: ', ' },
                )
              : '-',
          from_number: (
            <div className="flex gap-2 items-center">
              {processPhoneNumber(call.from_number)}

              {call.is_outgoing && <Tooltip content="Voice Agent" position="top"><Robot className="bold w-5 h-5" /></Tooltip>}

              {((call.is_outgoing &&
                call.stage_outcome === 'bot_disconnected') ||
                (!call.is_outgoing &&
                  call.stage_outcome === 'human_disconnected')) && (
                <Tooltip content="Ended the call" position="top"><PhoneSlash className="bold w-5 h-5" /></Tooltip>
              )}
            </div>
          ),
          to_number: (
            <div className="flex gap-2 items-center">
              {processPhoneNumber(call.to_number)}

              {!call.is_outgoing && <Tooltip content="Voice Agent" position="top"><Robot className="bold w-5 h-5" /></Tooltip>}

              {((!call.is_outgoing &&
                call.stage_outcome === 'bot_disconnected') ||
                (call.is_outgoing &&
                  call.stage_outcome === 'human_disconnected')) && (
                <Tooltip content="Ended the call" position="top"><PhoneSlash className="bold w-5 h-5" /></Tooltip>
              )}
            </div>
          ),
          status: CallStatus[call.status],
          actions: (
            <div className="flex justify-center items-center">
              <Tooltip content="Show call details" position="top">
                <MagnifyingGlass
                  className="w-5 h-10 cursor-pointer"
                  onClick={() => handleCallDetails(call)}
                />
              </Tooltip>
            </div>
          ),
        };
      }),
    );

    setRows(calls as never[]);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, filters, sortConfig, size]);

  const handlePageChange = async (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const filterByTimePeriod = (
    timePeriod: 'Last 24 Hours' | '7 Days' | '1 Month' | 'All Time',
  ) => {
    updateFilters({ timePeriod });
  };

  const filterByAgent = (agent: string) => {
    updateFilters({ agent });
  };

  const filterByStatus = (status: string) => {
    const statusKey = Object.keys(CallStatus).find(
      (key) => CallStatus[key] === status,
    );
    updateFilters({ status: statusKey ?? 'All Statuses' });
  };

  const searchAgents = useCallback(
    async (name: string) => {
      const queryObject: QueryObject = {
        ...(name.length !== 0 && { filters: JSON.stringify({ name }) }),
      };
      const query = new URLSearchParams(queryObject as Record<string, string>);

      const data = await get(`/agents?${query.toString()}`, {
        envId,
      });

      setAgents(data.items);

      return data.items.map((agent: Agent) => ({
        value: agent.id,
        label: agent.name,
      }));
    },
    [envId],
  );

  const handleCallDetails = (call: Call) => {
    openSidebar(<CallDetails call={call} />, 'Call Details');
  };

  const prepareFilterPeriod = (
    period: string,
  ): {
    start_time: ComparisonFilter | null;
    end_time: ComparisonFilter | null;
  } => {
    switch (period) {
      case 'Last 24 Hours':
        return filter24Hours;
      case '7 Days':
        return filter7Days;
      case '1 Month':
        return filter1Month;
      case 'All Time':
      default:
        return {
          start_time: null,
          end_time: null,
        };
    }
  };

  return (
    <div className="flex-1">
      <Heading
        title="Calls"
        subtitle="See the calls made using your agents here"
      >
        <div className="w-fit flex flex-1 items-center justify-end align-bottom">
          <div>
            <Label htmlFor="period">Period</Label>

            <SelectBox
              key={filters.timePeriod}
              options={periodsOptions}
              defaultValue={periodsOptions.find(
                (period) => period.value === filters.timePeriod,
              )}
              variant="outlined"
              color="primary"
              size="medium"
              className="min-w-[150px]"
              onChange={(value) =>
                filterByTimePeriod(
                  value as 'Last 24 Hours' | '7 Days' | '1 Month' | 'All Time',
                )
              }
            />
          </div>

          <div className="mt-[3px]">
            <Button
              className="ml-4 w-42 border-none flex items-center justify-center mt-12"
              variant="outlined"
              color="default"
              href="https://docs.fluents.ai/api-reference/calls"
              target="_blank"
            >
              View help doc
              <ArrowSquareOut className="ml-2" size={18} />
            </Button>
          </div>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="mb-6">
          <h3 className="text-2xl font-medium">Filter by:</h3>

          <div className="flex flex-col gap-4 lg:grid lg:grid-cols-6 lg:gap-4 mt-4">
            <div>
              <Label htmlFor="agents">Agents</Label>

              <SelectBox
                key={filters.agent}
                options={agentsOptions}
                defaultValue={agentsOptions.find(
                  (agent) => agent.value === filters.agent,
                )}
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) => filterByAgent(value)}
                className="col-span-2 hidden"
              />

              <AutoCompleteSelectBox
                defaultValue={agentsOptions.find(
                  (agent) => agent.value === filters.agent,
                )}
                fetchOptions={searchAgents}
                onChange={(value) => filterByAgent(value)}
                variant="outlined"
                color="primary"
                size="medium"
                disabled={loading}
                defaultOptions={agentsOptions}
              />
            </div>

            <div>
              <Label htmlFor="status">Statuses</Label>

              <SelectBox
                key={filters.status}
                options={statusesOptions}
                defaultValue={statusesOptions.find(
                  (status) => CallStatusKeys[status.value] === filters.status,
                )}
                variant="outlined"
                color="primary"
                size="medium"
                className="col-span-2"
                onChange={(value) => filterByStatus(value)}
              />
            </div>

            <div>
              <Input
                label="From Number"
                type="text"
                value={filters.from_number}
                onChange={(e) => updateFilters({ from_number: e.target.value })}
              />
            </div>

            <div>
              <Input
                label="To Number"
                type="text"
                value={filters.to_number}
                onChange={(e) => updateFilters({ to_number: e.target.value })}
              />
            </div>
          </div>
        </div>

        <Table
          headers={headers}
          rows={rows}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>
    </div>
  );
};
