import {
  ArrowSquareOut,
  MagnifyingGlass,
  Pencil,
  Trash,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, remove } from 'src/api/requests';
import { SideDropActions } from 'src/components/SideDropActions';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import Table from 'src/components/Table';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Prompt } from 'src/interfaces/prompt.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { limitString } from 'src/utils/limitString';

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'label', label: 'Name', width: '10%' },
  { key: 'content', label: 'Content', width: '50%' },
  { key: 'action', label: '', width: '20%', disableSorting: true },
];

export const ListPrompts = () => {
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const navigate = useNavigate();

  const [rows, setRows] = useState<Prompt[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [size, setSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      ...(filter.length !== 0 && {
        filters: JSON.stringify({ content: filter }),
      }),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/prompts?${query.toString()}`, {
      envId,
    });

    const prompts = data.items.map((item: Prompt) => ({
      id: <CopyableField value={item.id} notification={notification} />,
      label: item.label,
      content: limitString(item.content, 50),
      action: (
        <div className="flex justify-center items-center">
          <SideDropActions
            options={[
              {
                label: 'Edit',
                Icon: Pencil,
                onClick: () => navigate(`/prompt/edit/${item.id}`),
              },
              {
                label: 'Delete',
                Icon: Trash,
                color: 'error',
                show: !!item.user_id,
                onClick: () => openDeletePrompt(item),
              },
            ]}
          />
        </div>
      ),
    }));

    setRows(prompts as never[]);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, sortConfig, debouncedFilter, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    handlePageChange(1, size);
  };

  const openDeletePrompt = (prompt: Prompt) => {
    setModalDeleteOpen(true);
    setSelectedId(prompt.id);
  };

  const handleRemovePrompt = async () => {
    setLoading(true);
    try {
      await remove(`/prompts/${selectedId}`, { envId });
      notification.success('Prompt deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete prompt');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex-1">
      <Heading
        title="Prompt Library"
        subtitle="Prompts outline how your agent should behave during a call"
      >
        <div className="flex">
          <Button className="mt-6" onClick={() => navigate('/prompt/create')}>
            Create Prompt Template
          </Button>

          <Button
            className="ml-4 mt-6 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/prompts"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <Input
            type="text"
            required
            placeholder="Search prompts"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={() => submitFilter()}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={rows}
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>

      <Modal
        title="Delete Prompt"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedId('');
        }}
        actionButton={
          <Button color="default" onClick={handleRemovePrompt}>
            Delete Prompt
          </Button>
        }
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this prompt?
        </div>
      </Modal>
    </div>
  );
};
