import { Menu, MenuItems, MenuButton, MenuItem } from '@headlessui/react';
import { DotsThreeVertical, Icon } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type SideDropActionsProps = {
  options: {
    label: string;
    Icon: Icon;
    show?: boolean;
    color?:
      | 'primary'
      | 'secondary'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | 'default';
    onClick: () => void;
  }[];
};

const variantClasses = {
  primary: 'text-primary',
  secondary: 'text-gray-500',
  error: 'text-red-500',
  warning: 'text-yellow-500',
  success: 'text-green-500',
  info: 'text-blue-500',
  default: 'text-gray-500',
};

export const SideDropActions = ({ options }: SideDropActionsProps) => {
  if (!options.filter(({ show = true }) => show).length) return <div />;

  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  const ref = useRef<HTMLButtonElement>(null);

  const updatePosition = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, []);

  return (
    <div className="relative">
      <Menu>
        <MenuButton ref={ref} className="text-lg" onClick={updatePosition}>
          <DotsThreeVertical />
        </MenuButton>

        {createPortal(
          <MenuItems
            className="flex flex-col z-10 absolute bg-white border border-gray-200 shadow-lg rounded-lg focus:outline-none"
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
              transform: 'translate(-105%, 0%)',
            }}
          >
            {options
              .filter(({ show = true }) => show)
              .map(({ label, Icon, color = 'secondary', onClick }, index) => (
                <MenuItem key={index}>
                  {({ close }) => (
                    <button
                      onClick={() => {
                        onClick();
                        close();
                      }}
                      className={clsx(
                        'flex gap-4 items-center grow px-4 py-2 min-w-max hover:bg-neutral-150',
                        variantClasses[color],
                      )}
                    >
                      <Icon className="text-xl" /> {label}
                    </button>
                  )}
                </MenuItem>
              ))}
          </MenuItems>,
          document.body,
        )}
      </Menu>
    </div>
  );
};
