import clsx from 'clsx';
import { Button } from './Button';
import React, { useEffect } from 'react';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  actionButton?: React.ReactNode;
  className?: string;
};

export const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  actionButton,
  className,
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-active');
    } else {
      document.body.classList.remove('modal-active');
    }

    return () => document.body.classList.remove('modal-active');
  }, [isOpen]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if ((e.target as HTMLElement).classList.contains('modal-overlay')) {
      handleClose();
    }
  };

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if ((e.key === 'Escape' || e.key === 'Esc') && isOpen) {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen]);

  return (
    <div
      className={clsx(
        `modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50`,
        isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none',
      )}
      onClick={handleOverlayClick}
    >
      <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

      <div
        className={clsx(
          'modal-container bg-white w-11/12 lg:max-w-[60%] mx-auto rounded shadow-lg z-50 overflow-y-auto',
          className,
        )}
      >
        <div
          className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
          onClick={handleClose}
        >
          <svg
            className="fill-current text-white"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
          <span className="text-sm">(Esc)</span>
        </div>

        <div className="modal-content p-4 text-left">
          <div className="flex justify-between items-center pb-3">
            <p className="text-2xl font-medium">{title}</p>
            <div
              className="modal-close cursor-pointer z-50"
              onClick={handleClose}
            >
              <svg
                className="fill-current text-black"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>

          <div className="overflow-auto max-h-[80vh] p-1">{children}</div>

          <div className="flex justify-end pt-2">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
              className="mr-1"
            >
              Close
            </Button>

            {actionButton}
          </div>
        </div>
      </div>
    </div>
  );
};
