import {
  ArrowSquareOut,
  Code,
  MagnifyingGlass,
  Pencil,
  Trash,
  X,
} from '@phosphor-icons/react';
import { useDebounce } from 'ahooks';
import { useEffect, useState } from 'react';
import { get, patch, post, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Modal } from 'src/components/Modal';
import { MultiSelectBox } from 'src/components/MultiSelectBox';
import { SelectBox } from 'src/components/SelectBox';
import { SideDropActions } from 'src/components/SideDropActions';
import Table from 'src/components/Table';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { Webhook, WebhookPayload } from 'src/interfaces/webhook.interface';
import { isValidHttpUrl } from 'src/utils/url';

const subscriptionOptions = [
  { value: 'event_message', label: 'Message' },
  { value: 'event_action', label: 'Action' },
  { value: 'event_phone_call_connected', label: 'Phone Call Connected' },
  { value: 'event_phone_call_ended', label: 'Phone Call Ended' },
  {
    value: 'event_phone_call_did_not_connect',
    label: 'Phone Call Did Not Connect',
  },
  { value: 'event_transcript', label: 'Transcript' },
  { value: 'event_recording', label: 'Recording' },
  { value: 'event_human_detection', label: 'Human Detection' },
  { value: 'event_error', label: 'Error' },
];

const methods = [{ value: 'POST', label: 'POST' }];

const newWebhook: WebhookPayload = {
  subscriptions: [],
  url: '',
  method: 'POST',
};

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'subscriptions', label: 'Subscriptions', width: '30%' },
  { key: 'url', label: 'URL', width: '30%' },
  { key: 'method', label: 'Method', width: '10%' },
  { key: 'actions', label: '', width: '20%', disableSorting: true },
];

export const ListWebhooks = () => {
  const [equivalentCodeOpen, setEquivalentCodeOpen] = useState(false);
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [rows, setRows] = useState<Webhook[]>([]);
  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] = useState<WebhookPayload>(newWebhook);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });
  const [inputsWithError, setInputsWithError] = useState<string[]>([]);

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);
    setEquivalentCodeOpen(false);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
      ...(filter.length !== 0 && {
        filters: JSON.stringify({ url: filter }),
      }),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/webhooks?${query.toString()}`, {
      envId,
    });

    const items = data.items;

    setRows(items);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchData(currentPage, size);
    }
  }, [currentPage, sortConfig, debouncedFilter, size]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    setSize(perPage);
  };

  const handleSizeChange = (perPage: number) => {
    setSize(perPage);
    setCurrentPage(1);
    fetchData(1, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const submitFilter = () => {
    handlePageChange(1, size);
  };

  const resetForm = () => {
    setForm(newWebhook);
  };

  const openModalNewWebhook = () => {
    setModalOpen(true);
    setSelectedId('');
    resetForm();
  };

  const openModalEditWebhook = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
    const webhook = rows.find((item) => item.id === id);

    if (webhook) {
      setForm({
        subscriptions: webhook.subscriptions,
        url: webhook.url,
        method: webhook.method,
      });
    }
  };

  const closeModal = () => {
    setEquivalentCodeOpen(false);
    setModalOpen(false);
    setSelectedId('');
    resetForm();
  };

  const submitModal = async () => {
    setLoading(true);

    if (selectedId) {
      await patch(`/webhooks/${selectedId}`, form, {
        envId,
      });
      notification.success('Webhook updated successfully');
    } else {
      await post('/webhooks', form, {
        envId,
      });
      notification.success('Webhook created successfully');
    }

    fetchData(currentPage, size);

    setLoading(false);

    closeModal();
  };

  const handleEndpointWebhook = () => {
    if (selectedId) {
      return `webhooks/update?id=${selectedId}`;
    } else {
      return 'webhooks/create';
    }
  };

  const translateSubscription = (subscription_key: string) => {
    const subscription = subscriptionOptions.find(
      (item) => item.value === subscription_key,
    );

    return subscription ? subscription.label : subscription_key;
  };

  useEffect(() => {
    if (equivalentCodeOpen && !loading) {
      setEquivalentCodeOpen(true);
    }
  }, [form, equivalentCodeOpen]);

  useEffect(() => {
    if (form.method === 'GET') {
      setForm({
        ...form,
        subscriptions: form.subscriptions.filter(
          (item) =>
            item === 'event_phone_call_connected' ||
            item === 'event_phone_call_ended',
        ),
      });
    }
  }, [form.method]);

  const isValidForm = () => {
    return form.subscriptions.length === 0 || form.url === '';
  };

  const disabledSubmit = () => {
    if (loading) return true;

    return isValidForm();
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValidHttpUrl(e.target.value)) {
      setInputsWithError([...inputsWithError, 'url']);
      setForm({ ...form, url: '' });
    } else {
      setInputsWithError(inputsWithError.filter((item) => item !== 'url'));
    }
  };

  const openDeleteWebhook = (webhook: Webhook) => {
    setModalDeleteOpen(true);
    setSelectedId(webhook.id);
  };

  const handleRemoveWebhook = async () => {
    setLoading(true);
    try {
      await remove(`/webhooks/${selectedId}`, { envId });
      notification.success('Webhook deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete webhook');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex-1">
      <Heading
        title="Webhooks"
        subtitle="Webhooks allow you to listen to when specific events happen during a conversation"
      >
        <div className="flex mt-6">
          <Button onClick={openModalNewWebhook}>Create Webhook</Button>

          <Button
            className="ml-4 w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/webhooks"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="flex mb-6 w-full md:w-96 self-end">
          <Input
            type="text"
            required
            placeholder="Search webhooks"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                submitFilter();
              }
            }}
          />

          <Button className="ml-2" onClick={submitFilter}>
            <MagnifyingGlass size={20} />
          </Button>
        </div>

        <Table
          headers={headers}
          rows={
            rows.map((item: Webhook) => ({
              ...item,
              id: <CopyableField value={item.id} notification={notification} />,
              subscriptions: (
                <div className="flex flex-wrap">
                  {item.subscriptions.map((subscription) => (
                    <div
                      key={subscription}
                      className="m-1 bg-primary p-1 text-white rounded-md"
                    >
                      {translateSubscription(subscription)}
                    </div>
                  ))}
                </div>
              ),
              actions: item.user_id && (
                <div className="flex justify-center items-center">
                  <SideDropActions
                    options={[
                      {
                        label: 'Edit',
                        Icon: Pencil,
                        onClick: () => openModalEditWebhook(item.id as string),
                      },
                      {
                        label: 'Delete',
                        Icon: Trash,
                        color: 'error',
                        onClick: () => openDeleteWebhook(item),
                      },
                    ]}
                  />
                </div>
              ),
            })) as never[]
          }
          totalItems={totalItems}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onSort={handleSort}
          loading={loading}
          setSize={handleSizeChange}
          defaultSize={size}
        />
      </ContentWrapper>

      <Modal
        title={`${selectedId ? 'Edit' : 'New'} Webhook`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button onClick={submitModal} disabled={disabledSubmit()}>
            {selectedId ? 'Save Webhook' : 'Create Webhook'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              equivalentCodeOpen
                ? setEquivalentCodeOpen(false)
                : setEquivalentCodeOpen(true)
            }
            className="flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="flex justify-end italic text-sm mt-4">
          * Required fields
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="mb-6">
            <Label required htmlFor="subscriptions">
              Subscriptions
            </Label>

            <MultiSelectBox
              key={form.subscriptions.join('-')}
              options={subscriptionOptions.filter((item) =>
                form.method === 'GET'
                  ? item.value === 'event_phone_call_connected' ||
                    item.value === 'event_phone_call_ended'
                  : item,
              )}
              defaultValue={subscriptionOptions.filter((item) =>
                form.subscriptions.includes(item.value),
              )}
              variant="outlined"
              color="primary"
              size="medium"
              className="min-w-[140px]"
              onChange={(value) => setForm({ ...form, subscriptions: value })}
              disabled={loading}
            />
          </div>

          <div className="mb-6">
            <Label required htmlFor="url">
              URL
            </Label>

            <Input
              type="text"
              id="url"
              placeholder="https://example.com"
              value={form.url}
              onChange={(e) => setForm({ ...form, url: e.target.value })}
              onBlur={handleUrlChange}
              disabled={loading}
              error={
                inputsWithError.includes('url')
                  ? 'Please enter a valid URL'
                  : ''
              }
            />
          </div>

          <div className="mb-6">
            <Label htmlFor="method">Method</Label>

            {form.method === 'GET' && (
              <>
                <span className="text-gray-700 text-sm font-bold block mb-4">
                  Current: {form.method}
                </span>

                <span className="text-gray-700 text-xs font-bold block">
                  Note: GET requests are no longer supported as they were
                  previously. You can edit this webhook, but you will not be
                  able to create new ones using the GET method.
                </span>
              </>
            )}

            {form.method !== 'GET' && (
              <SelectBox
                key={form.method}
                options={methods}
                variant="outlined"
                color="primary"
                size="medium"
                onChange={(value) =>
                  setForm({ ...form, method: value as string })
                }
                className="min-w-[140px]"
                defaultValue={methods.find(
                  (item) => item.value === form.method,
                )}
                disabled={loading}
              />
            )}
          </div>
        </div>

        {equivalentCodeOpen && (
          <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="block text-gray-700 text-md font-bold mb-2">
                Equivalent Code
              </h3>

              <X
                className="cursor-pointer inline-block w-5 h-5"
                onClick={() => setEquivalentCodeOpen(false)}
              />
            </div>

            <EquivalentCode
              payload={form}
              endpoint={handleEndpointWebhook()}
              method="POST"
            />
          </div>
        )}
      </Modal>

      <Modal
        title="Delete Webhook"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedId('');
        }}
        actionButton={
          <Button color="default" onClick={handleRemoveWebhook}>
            Delete Webhook
          </Button>
        }
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this webhook?
        </div>
      </Modal>
    </div>
  );
};
