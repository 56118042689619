import {
  ArrowSquareOut,
  Code,
  Copy,
  Eye,
  EyeClosed,
  Pencil,
  Trash,
  X,
} from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { get, patch, post, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { CopyableField } from 'src/components/CopyableField';
import { EquivalentCode } from 'src/components/EquivalentCode';
import { Heading } from 'src/components/Heading';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Modal } from 'src/components/Modal';
import { Option, SelectBox } from 'src/components/SelectBox';
import { SideDropActions } from 'src/components/SideDropActions';
import Table from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import {
  AccountConnection,
  AccountConnectionPayload,
} from 'src/interfaces/accountConnection.interface';
import { QueryObject } from 'src/interfaces/queryObject.interface';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { obfuscateToken } from 'src/utils/token';

const providerOptions: { [key: string]: string } = {
  account_connection_twilio: 'Twilio',
  account_connection_openai: 'OpenAI',
  account_connection_elevenlabs: 'ElevenLabs',
  account_connection_deepgram: 'Deepgram',
  account_connection_cartesia: 'Cartesia',
  account_connection_playht: 'PlayHT',
  account_connection_azure_speech: 'Azure Speech',
  account_connection_google: 'Google',
  account_connection_azure_llm: 'Azure LLM',
};

const defaultProviders = Object.entries(providerOptions).map(
  ([value, label]) => ({
    value,
    label,
  }),
);

const newAccountConnection: AccountConnectionPayload = {
  credentials: {
    twilio_account_sid: '',
    twilio_auth_token: '',
  },
};

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'type', label: 'Provider', width: '15%' },
  { key: 'info', label: 'Provider Info', width: '40%', disableSorting: true },
  { key: 'actions', label: '', width: '15%', disableSorting: true },
];

export const ListAccountConnections = () => {
  const [equivalentCodeOpen, setEquivalentCodeOpen] = useState(false);
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const [providers, setProviders] = useState<Option[]>(defaultProviders);
  const [providersKeyObfuscated, setProvidersKeyObfuscated] = useState<{
    [key: string]: boolean;
  }>({
    account_connection_twilio: true,
    account_connection_openai: true,
  });
  const [rows, setRows] = useState<AccountConnection[]>([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [form, setForm] =
    useState<AccountConnectionPayload>(newAccountConnection);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string | null;
  }>({ key: null, direction: null });

  const fetchData = async (page: number, perPage: number) => {
    setLoading(true);

    const queryObject: QueryObject = {
      page: String(page),
      size: String(perPage),
    };

    if (sortConfig.key) {
      queryObject.sort_column = sortConfig.key;
      queryObject.sort_desc =
        sortConfig.direction === 'desc' ? 'true' : 'false';
    }

    const query = new URLSearchParams(queryObject as Record<string, string>);

    const data = await get(`/account-connections?${query.toString()}`, {
      envId,
    });

    const items = data.items;

    const providersToBeObfuscated: { [key: string]: boolean } = {};

    for (const item of items) {
      providersToBeObfuscated[item.id] = true;
    }

    setProvidersKeyObfuscated(providersToBeObfuscated);

    setRows(items);
    setTotalItems(data.total);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage, size);
  }, [currentPage, sortConfig]);

  const handlePageChange = (page: number, perPage: number) => {
    setCurrentPage(page);
    fetchData(page, perPage);
  };

  const handleSort = (key: string, direction: string) => {
    setSortConfig({ key, direction });
  };

  const resetForm = () => {
    setForm(newAccountConnection);
  };

  const openModalNewAccountConnection = () => {
    setModalOpen(true);
    setSelectedId('');
    resetForm();
  };

  const openModalEditAccountConnection = (id: string) => {
    setModalOpen(true);
    setSelectedId(id);
    const account = rows.find((item) => item.id === id);

    if (account) {
      setForm({
        credentials: {
          twilio_account_sid: account.credentials.twilio_account_sid,
          twilio_auth_token: account.credentials.twilio_auth_token,
          openai_api_key: account.credentials.openai_api_key,
          api_key: account.credentials.api_key,
          region: account.credentials.region,
          userId: account.credentials.userId,
        },
        type: account.type,
      });
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedId('');
    setEquivalentCodeOpen(false);
    resetForm();
  };

  const submitModal = async () => {
    setLoading(true);

    if (selectedId) {
      await patch(`/account-connections/${selectedId}`, form, {
        envId,
      });
      notification.success('Account updated successfully');
    } else {
      await post('/account-connections', form, {
        envId,
      });
      notification.success('Account created successfully');
    }

    fetchData(currentPage, size);

    setLoading(false);

    closeModal();
  };

  const changeProvider = (value: string) => {
    if (value === 'account_connection_twilio') {
      setForm({
        ...form,
        credentials: {
          twilio_account_sid: '',
          twilio_auth_token: '',
        },
        type: value,
      });
    } else {
      setForm({
        ...form,
        credentials: {
          openai_api_key: '',
        },
        type: value,
      });
    }
  };

  const hasTwilio = rows.some(
    (item) => item.type === 'account_connection_twilio',
  );

  const hasOpenAI = rows.some(
    (item) => item.type === 'account_connection_openai',
  );

  const hasElevenLabs = rows.some(
    (item) => item.type === 'account_connection_elevenlabs',
  );

  const hasDeepgram = rows.some(
    (item) => item.type === 'account_connection_deepgram',
  );

  const hasCartesia = rows.some(
    (item) => item.type === 'account_connection_cartesia',
  );

  const hasPlayht = rows.some(
    (item) => item.type === 'account_connection_playht',
  );

  const hasAzureSpeech = rows.some(
    (item) => item.type === 'account_connection_azure_speech',
  );

  const hasGoogle = rows.some(
    (item) => item.type === 'account_connection_google',
  );

  const hasAzureLlm = rows.some(
    (item) => item.type === 'account_connection_azure_llm',
  );

  const hasAllProviders =
    hasTwilio &&
    hasOpenAI &&
    hasElevenLabs &&
    hasDeepgram &&
    hasCartesia &&
    hasPlayht &&
    hasAzureSpeech &&
    hasGoogle &&
    hasAzureLlm;

  useEffect(() => {
    const missingProviders = Object.entries(providerOptions)
      .filter(([key]) => !rows.some((item) => item.type === key))
      .map(([value, label]) => ({ value, label }));

    setProviders(missingProviders);
  }, [rows]);

  const toggleProvidersKeyObfuscated = (key: string) => {
    setProvidersKeyObfuscated({
      ...providersKeyObfuscated,
      [key]: !providersKeyObfuscated[key],
    });
  };

  const endpoint = selectedId
    ? `account_connections/update?id=${selectedId}`
    : 'account_connections/create';

  const isValidForm = (form: AccountConnectionPayload) => {
    if (
      form.type === 'account_connection_twilio' &&
      (!form.credentials.twilio_account_sid ||
        !form.credentials.twilio_auth_token)
    )
      return true;

    if (
      form.type === 'account_connection_openai' &&
      !form.credentials.openai_api_key
    )
      return true;

    return false;
  };

  const disabledSubmit = () => {
    if (loading) return true;

    if (!form.type) return true;

    return isValidForm(form);
  };

  const openDeleteProvider = (provider: AccountConnection) => {
    setModalDeleteOpen(true);
    setSelectedId(provider.id);
  };

  const handleRemoveProvider = async () => {
    setLoading(true);
    try {
      await remove(`/account-connections/${selectedId}`, { envId });
      notification.success('Provider deleted successfully');
      fetchData(currentPage, size);
      setModalDeleteOpen(false);
    } catch {
      notification.error('Failed to delete provider');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (equivalentCodeOpen && !loading) {
      setEquivalentCodeOpen(true);
    }
  }, [form, equivalentCodeOpen]);

  return (
    <div className="flex-1">
      <Heading
        title="Providers"
        subtitle="Manage your account connections and providers."
      >
        <div className="flex mt-6">
          {!hasAllProviders && (
            <Button className="mr-2" onClick={openModalNewAccountConnection}>
              Add Account
            </Button>
          )}

          <Button
            className="w-42 border-none flex items-center justify-center"
            variant="outlined"
            color="default"
            href="https://docs.fluents.ai/api-reference/account_connections"
            target="_blank"
          >
            View help doc
            <ArrowSquareOut className="ml-2" size={18} />
          </Button>
        </div>
      </Heading>

      <ContentWrapper>
        <div className="pb-3">
          <Table
            headers={headers}
            rows={
              rows.map((item: AccountConnection) => ({
                ...item,
                id: (
                  <CopyableField value={item.id} notification={notification} />
                ),
                type:
                  defaultProviders.find((type) => type.value === item.type)
                    ?.label || item.type.split('account_connection_')[1],
                info: (
                  <div>
                    {item.type === 'account_connection_twilio' && (
                      <div>
                        <p>
                          <span className="font-bold">Twilio Account SID:</span>{' '}
                          {item.credentials.twilio_account_sid}
                        </p>
                        <p>
                          <span className="font-bold">Twilio Auth Token:</span>{' '}
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              toggleProvidersKeyObfuscated(item.id)
                            }
                          >
                            {providersKeyObfuscated[item.id]
                              ? obfuscateToken(
                                  item.credentials.twilio_auth_token,
                                )
                              : item.credentials.twilio_auth_token}{' '}
                            {providersKeyObfuscated[item.id] ? (
                              <Eye className="inline-block w-5 h-5" />
                            ) : (
                              <EyeClosed className="inline-block w-5 h-5" />
                            )}
                          </span>
                          <Tooltip content="Copy to clipboard" position="top">
                            <Copy
                              className="cursor-pointer inline-block w-5 h-5 ml-2"
                              onClick={() =>
                                copyToClipboard(
                                  item.credentials.twilio_auth_token,
                                  notification,
                                )
                              }
                            />
                          </Tooltip>
                        </p>
                      </div>
                    )}

                    {item.type === 'account_connection_openai' && (
                      <div>
                        <p>
                          <span className="font-bold">OpenAI API Key:</span>{' '}
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              toggleProvidersKeyObfuscated(item.id)
                            }
                          >
                            {providersKeyObfuscated[item.id]
                              ? obfuscateToken(item.credentials.openai_api_key)
                              : item.credentials.openai_api_key}{' '}
                            {providersKeyObfuscated[item.id] ? (
                              <Eye className="inline-block w-5 h-5" />
                            ) : (
                              <EyeClosed className="inline-block w-5 h-5" />
                            )}
                          </span>
                          <Tooltip content="Copy to clipboard" position="top">
                            <Copy
                              className="cursor-pointer inline-block w-5 h-5 ml-2"
                              onClick={() =>
                                copyToClipboard(
                                  item.credentials.openai_api_key,
                                  notification,
                                )
                              }
                            />
                          </Tooltip>
                        </p>
                      </div>
                    )}

                    {item.type !== 'account_connection_twilio' &&
                      item.type !== 'account_connection_openai' && (
                        <div>
                          <p>
                            <span className="font-bold">API Key:</span>{' '}
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                toggleProvidersKeyObfuscated(item.id)
                              }
                            >
                              {providersKeyObfuscated[item.id]
                                ? obfuscateToken(item.credentials.api_key)
                                : item.credentials.api_key}{' '}
                              {providersKeyObfuscated[item.id] ? (
                                <Eye className="inline-block w-5 h-5" />
                              ) : (
                                <EyeClosed className="inline-block w-5 h-5" />
                              )}
                            </span>
                            <Tooltip content="Copy to clipboard" position="top">
                              <Copy
                                className="cursor-pointer inline-block w-5 h-5 ml-2"
                                onClick={() =>
                                  copyToClipboard(
                                    item.credentials.api_key,
                                    notification,
                                  )
                                }
                              />
                            </Tooltip>
                          </p>
                          <p>
                            {item.type === 'account_connection_playht' && (
                              <span>
                                <span className="font-bold">User ID:</span>{' '}
                                {item.credentials.userId}
                              </span>
                            )}
                            {item.type ===
                              'account_connection_azure_speech' && (
                              <span>
                                <span className="font-bold">Region:</span>{' '}
                                {item.credentials.region}
                              </span>
                            )}

                            {item.type === 'account_connection_azure_llm' && (
                              <span>
                                <span className="font-bold">URL:</span>{' '}
                                {item.credentials.url}
                              </span>
                            )}
                          </p>
                        </div>
                      )}
                  </div>
                ),
                actions: (
                  <div className="flex justify-center items-center">
                    <SideDropActions
                      options={[
                        {
                          label: 'Edit',
                          Icon: Pencil,
                          onClick: () =>
                            openModalEditAccountConnection(item.id as string),
                        },
                        {
                          label: 'Delete',
                          Icon: Trash,
                          color: 'error',
                          onClick: () => openDeleteProvider(item),
                        },
                      ]}
                    />
                  </div>
                ),
              })) as never[]
            }
            totalItems={totalItems}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onSort={handleSort}
            loading={loading}
            setSize={setSize}
            defaultSize={size}
            disablePagination
          />
        </div>
      </ContentWrapper>

      <Modal
        title={`${selectedId ? 'Edit' : 'New'} Account Connection`}
        isOpen={modalOpen}
        onClose={() => closeModal()}
        actionButton={
          <Button onClick={submitModal} disabled={disabledSubmit()}>
            {selectedId ? 'Save Account' : 'Add Account'}
          </Button>
        }
      >
        <div className="flex justify-end mb-1">
          <Button
            onClick={() =>
              equivalentCodeOpen
                ? setEquivalentCodeOpen(false)
                : setEquivalentCodeOpen(true)
            }
            className="flex items-center justify-center"
          >
            <Code className="mr-2" size={20} />
            Show equivalent code
          </Button>
        </div>

        <div className="flex justify-end italic text-sm mt-4">
          * Required fields
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {!selectedId && (
            <div>
              <div className="mb-6">
                <Label required>Provider</Label>

                <SelectBox
                  options={providers}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onChange={(value) => changeProvider(value)}
                  className="min-w-[140px]"
                  defaultValue={providers.find(
                    (item) => item.value === form.type,
                  )}
                  disabled={loading}
                  key={form.type}
                />
              </div>
            </div>
          )}

          <div>
            {form.type == 'account_connection_twilio' && (
              <div className="mb-6">
                <Input
                  label="Twilio Account SID"
                  type="text"
                  id="twilio-account-sid"
                  placeholder="Enter Twilio Account SID"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        twilio_account_sid: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.twilio_account_sid}
                  disabled={loading}
                  required
                />
              </div>
            )}

            {form.type == 'account_connection_twilio' && (
              <div className="mb-6">
                <Input
                  label="Twilio Auth Token"
                  type="password"
                  id="twilio-auth-token"
                  placeholder="Enter Twilio Auth Token"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        twilio_auth_token: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.twilio_auth_token}
                  disabled={loading}
                  required
                />
              </div>
            )}

            {form.type == 'account_connection_openai' && (
              <div className="mb-6">
                <Input
                  label="OpenAI API Key"
                  type="password"
                  id="openai-api-key"
                  placeholder="Enter OpenAI API Key"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        openai_api_key: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.openai_api_key}
                  disabled={loading}
                  required
                />
              </div>
            )}

            {form.type !== undefined &&
              form.type !== '' &&
              form.type !== 'account_connection_twilio' &&
              form.type !== 'account_connection_openai' && (
                <div className="mb-6">
                  <Input
                    label={`${
                      defaultProviders.find((type) => type.value === form.type)
                        ?.label
                    } API Key`}
                    type="password"
                    id="api-key"
                    placeholder="Enter API Key"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        credentials: {
                          ...form.credentials,
                          api_key: e.target.value,
                        },
                      })
                    }
                    value={form.credentials.api_key}
                    disabled={loading}
                    required
                  />
                </div>
              )}

            {form.type == 'account_connection_playht' && (
              <div className="mb-6">
                <Input
                  label="User ID"
                  type="text"
                  id="userId"
                  placeholder="Enter User ID"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        userId: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.userId}
                  disabled={loading}
                  required
                />
              </div>
            )}

            {form.type == 'account_connection_azure_speech' && (
              <div className="mb-6">
                <Input
                  label="Region"
                  type="text"
                  id="region"
                  placeholder="Enter Region"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: {
                        ...form.credentials,
                        region: e.target.value,
                      },
                    })
                  }
                  value={form.credentials.region}
                  disabled={loading}
                  required
                />
              </div>
            )}

            {form.type == 'account_connection_azure_llm' && (
              <div className="mb-6">
                <Input
                  label="URL"
                  type="text"
                  id="url"
                  placeholder="Enter URL"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      credentials: { ...form.credentials, url: e.target.value },
                    })
                  }
                  value={form.credentials.url}
                  disabled={loading}
                />
              </div>
            )}
          </div>
        </div>

        {equivalentCodeOpen && (
          <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="block text-gray-700 text-md font-bold mb-2">
                Equivalent Code
              </h3>

              <X
                className="cursor-pointer inline-block w-5 h-5"
                onClick={() => setEquivalentCodeOpen(false)}
              />
            </div>

            <EquivalentCode payload={form} endpoint={endpoint} method="POST" />
          </div>
        )}
      </Modal>

      <Modal
        title="Delete Provider"
        isOpen={modalDeleteOpen}
        onClose={() => {
          setModalDeleteOpen(false);
          setSelectedId('');
        }}
        actionButton={
          <Button color="default" onClick={handleRemoveProvider}>
            Delete Provider
          </Button>
        }
        className="w-96"
      >
        <div className="text-center">
          Are you sure you want to delete this provider?
        </div>
      </Modal>
    </div>
  );
};
