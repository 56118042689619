import {
  ArrowLineLeft,
  FlowArrow,
  Gear,
  List,
  Microphone,
  Numpad,
  Phone,
  Plugs,
  SignOut,
  TextIndent,
  UserCircle,
  UserSound,
  Wallet,
  WebhooksLogo,
} from '@phosphor-icons/react';
import { useLocalStorageState } from 'ahooks';
import clsx from 'clsx';
import { getAuth } from 'firebase/auth';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { breakpoints } from 'src/hooks/useBreakpoint';
import { EnvironmentSwitcher } from './EnvironmentSwitcher';
import { MenuItem, MenuItemGroup } from './Menu';

const selectedNavClass = 'text-white bg-control-plane-300';

export const Sidebar = ({
  classNameUserMenu: classNameUserMenu,
}: {
  classNameUserMenu?: string;
}) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorageState<boolean>(
    '@control-plane/sidebar-collapsed',
    {
      defaultValue: false,
      listenStorageChange: true,
    },
  );
  const [fireBaseDisplayName] = useLocalStorageState<string | null>(
    '@control-plane/fireBaseDisplayName',
    {
      defaultValue: '',
      listenStorageChange: true,
    },
  );

  const auth = getAuth();
  const { updateEnvironment } = useEnvironment();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < breakpoints.tablet);
    };

    const handleResizeLoadIfNotInLocalStorage = () => {
      if (window.innerWidth < breakpoints.tablet && !isCollapsed) {
        setIsCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResizeLoadIfNotInLocalStorage);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResizeLoadIfNotInLocalStorage);
    };
  }, [isCollapsed]);

  return (
    <div
      className={clsx(
        'p-5 h-screen bg-gray-100 text-white flex flex-col transition-all duration-300 fixed z-50 overflow-hidden',
        isCollapsed ? 'w-20' : 'w-64',
      )}
    >
      <div
        className={clsx(
          'py-4 flex items-center',
          isCollapsed ? 'justify-center' : 'justify-between',
        )}
      >
        {isCollapsed ? (
          <button
            onClick={toggleSidebar}
            className="text-white focus:outline-none mb-8"
          >
            <List className="text-control-plane-700" size={24} />
          </button>
        ) : (
          <Link to="/">
            <img src="/assets/logo.png" alt="LOGO" className="logo" />
          </Link>
        )}
        {!isCollapsed && (
          <button
            onClick={toggleSidebar}
            className="text-white focus:outline-none"
          >
            <ArrowLineLeft className="text-control-plane-700" size={24} />
          </button>
        )}
      </div>
      {!isCollapsed && (
        <div className="my-5">
          <EnvironmentSwitcher
            onChange={(value) => {
              updateEnvironment({
                userId: auth.currentUser?.uid || '',
                envId: value,
              });
              window.location.reload();
            }}
            className="w-20 text-black"
          />
        </div>
      )}

      <nav className="flex flex-col justify-between h-full overflow-y-auto overflow-x-hidden sidebar-nav">
        <ul className="space-y-2">
          <MenuItemGroup title="Performance" isCollapsed={isCollapsed}>
            <MenuItem
              icon={<Phone size={20} />}
              title="Calls"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/calls')}
              className={location.pathname === '/calls' ? selectedNavClass : ''}
            />
          </MenuItemGroup>
          <MenuItemGroup title="Setup" isCollapsed={isCollapsed}>
            <MenuItem
              icon={<UserSound size={20} />}
              title="Agents"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/agents')}
              className={
                location.pathname === '/agents' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<TextIndent size={20} />}
              title="Prompts"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/prompts')}
              className={
                location.pathname === '/prompts' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<Microphone size={20} />}
              title="Voices"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/voices')}
              className={
                location.pathname === '/voices' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<Numpad size={20} />}
              title="Numbers"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/numbers')}
              className={
                location.pathname === '/numbers' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<Plugs size={20} />}
              title="Providers"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/providers')}
              className={
                location.pathname === '/providers' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<FlowArrow size={20} />}
              title="Actions"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/actions')}
              className={
                location.pathname === '/actions' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<WebhooksLogo size={20} />}
              title="Webhooks"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/webhooks')}
              className={
                location.pathname === '/webhooks' ? selectedNavClass : ''
              }
            />
          </MenuItemGroup>
        </ul>
        <ul className={classNameUserMenu}>
          <MenuItemGroup title="Accounts" isCollapsed={isCollapsed}>
            <MenuItem
              icon={<UserCircle size={20} />}
              title={fireBaseDisplayName || ''}
              isCollapsed={isCollapsed}
              onClick={() => navigate('/profile')}
              className={
                location.pathname === '/profile' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<Gear size={20} />}
              title="Settings"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/settings')}
              className={
                location.pathname === '/settings' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<Wallet size={20} />}
              title="Billing"
              isCollapsed={isCollapsed}
              onClick={() => navigate('/billing')}
              className={
                location.pathname === '/billing' ? selectedNavClass : ''
              }
            />
            <MenuItem
              icon={<SignOut size={20} />}
              title="Log out"
              isCollapsed={isCollapsed}
              onClick={() => {
                auth.signOut();
                window.location.href = '/login';
              }}
            />
          </MenuItemGroup>
        </ul>
      </nav>
    </div>
  );
};
