import { UnloggedCard } from 'src/components/UnloggedCard';

export const NotFound = () => {
  return (
    <UnloggedCard>
      <h2 className="text-2xl font-bold mb-6 text-center">
        404 - Page Not Found
      </h2>

      <div className="flex-1 bg-gray-100 p-6">
        <p>Please check the URL in the address bar and try again.</p>

        <div className="flex justify-center mt-6">
          <a
            href="/"
            className="btn btn-primary bg-control-plane-300 text-white rounded-md p-2"
          >
            Return to Home
          </a>
        </div>
      </div>
    </UnloggedCard>
  );
};
