import clsx from 'clsx';

interface LabelProps {
  children: React.ReactNode;
  className?: string;
  required?: boolean;
  htmlFor?: string;
}

export const Label = ({
  children,
  className,
  required,
  htmlFor,
}: LabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(
        'block text-md font-regular mb-2',
        required ? 'required-field' : '',
        className,
      )}
    >
      {children}
    </label>
  );
};
