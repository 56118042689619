import { useDebounceEffect } from 'ahooks';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { get, put } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Loading } from 'src/components/Loading';
import { Modal } from 'src/components/Modal';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Organization } from 'src/interfaces/organization.interface';

export const ChangeOrganization = () => {
  const [loading, setLoading] = useState(true);
  const notification = useNotification();
  const { environment } = useEnvironment();
  const envId = environment?.envId;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [organizationName, setOrganizationName] = useState<string | null>(null);

  const acceptInvitation = async (organizationId: string) => {
    try {
      await put(`/users/organization/${organizationId}`, {
        envId,
      });
      navigate('/settings?showChangeOrganization=true');
    } catch (error) {
      notification.error('Failed to update organization');
      console.error('Error updating organization:', error);
      navigate('/settings');
    }
  };

  useDebounceEffect(() => {
    const orgId = searchParams.get('organizationId');
    if (!orgId) {
      navigate('/');
      return;
    }

    setOrganizationId(orgId);
    setShowConfirmModal(true);
  }, [searchParams]);

  useEffect(() => {
    if (organizationId) {
      const fetchOrganization = async () => {
        setLoading(true);

        const organization: Organization = await get(
          `/organizations/${organizationId}`,
          {
            envId,
          },
        );

        if (organization.name) {
          setOrganizationName(organization.name);
        }

        setLoading(false);
      };

      fetchOrganization();
    }
  }, [organizationId]);

  const handleConfirm = () => {
    if (organizationId) {
      acceptInvitation(organizationId);
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="flex-1">
      {loading && (
        <ContentWrapper>
          <Loading />
        </ContentWrapper>
      )}

      <Modal
        title="Accept Organization Invitation"
        isOpen={showConfirmModal}
        onClose={handleCancel}
        actionButton={
          <Button onClick={handleConfirm}>Accept Invitation</Button>
        }
        className="lg:max-w-[600px] md:max-w-[500px]"
      >
        <div className="space-y-4">
          <p>
            You've been invited to join <b>{organizationName}</b> org!
          </p>
          <p>
            Would you like to accept this invitation to{' '}
            <b>{organizationName}</b>?
          </p>
        </div>
      </Modal>
    </div>
  );
};
