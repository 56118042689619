import { ContentWrapper } from 'src/components/ContentWrapper';
import { Heading } from 'src/components/Heading';
import { Button } from 'src/components/Button';
import Table from 'src/components/Table';
import { Pencil } from '@phosphor-icons/react';
import { useConnections, useIntegrationApp } from '@integration-app/react';

type ListWorkflowsProps = {
  isLoading: boolean;
};

const headers = [
  { key: 'id', label: 'ID', width: '8rem' },
  { key: 'name', label: 'Provider Info', width: '40%', disableSorting: true },
  { key: 'actions', label: '', width: '20%', disableSorting: true },
];

export const ListWorkflows = ({ isLoading }: ListWorkflowsProps) => {
  const integrationApp = useIntegrationApp();
  const { items, loading } = useConnections();

  return (
    <div className="flex-1">
      <Heading title="Workflow" subtitle="Manage your connections here.">
        <div className="flex mt-6">
          <Button
            className="flex justify-center items-center"
            onClick={() => integrationApp?.open()}
          >
            Connect New Source
          </Button>
        </div>
      </Heading>
      <ContentWrapper>
        <div className="flex flex-col gap-5">
          <Table
            headers={headers}
            rows={items.map((connection) => ({
              id: connection.id,
              name: <p>{connection.integration?.name}</p>,
              actions: (
                <div className="flex">
                  <Button
                    className="ml-2"
                    onClick={() =>
                      integrationApp
                        ?.integration(connection.integrationId)
                        .open()
                    }
                  >
                    <Pencil className="cursor-pointer inline-block w-5 h-5" />
                  </Button>
                </div>
              ),
            }))}
            totalItems={items.length}
            currentPage={1}
            onPageChange={() => {}}
            onSort={() => {}}
            loading={isLoading || loading}
            disablePagination
          />
        </div>
      </ContentWrapper>
    </div>
  );
};
