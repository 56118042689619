import clsx from 'clsx';
import React from 'react';

type ButtonProps = {
  children: React.ReactNode;
  variant?: 'contained' | 'outlined' | 'text';
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default';
  size?: 'small' | 'medium' | 'large';
  fontWeight?: 'light' | 'regular' | 'medium' | 'bold';
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  href?: string;
  target?: string;
  rel?: string;
};

const variantClasses = {
  contained: {
    primary: 'bg-primary text-white hover:bg-primary/90 active:bg-primary/80',
    secondary: 'bg-gray-500 text-white hover:bg-gray-600 active:bg-gray-700',
    error: 'bg-red-500 text-white hover:bg-red-600 active:bg-red-700',
    warning:
      'bg-yellow-500 text-white hover:bg-yellow-600 active:bg-yellow-700',
    success: 'bg-green-500 text-white hover:bg-green-600 active:bg-green-700',
    info: 'bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-700',
    default: 'bg-gray-500 text-white hover:bg-gray-600 active:bg-gray-700',
  },
  outlined: {
    primary:
      'border-2 border-primary text-primary hover:bg-primary/10 active:bg-primary/20',
    secondary:
      'border-2 border-gray-500 text-gray-500 hover:bg-gray-100 active:bg-gray-200',
    error:
      'border-2 border-red-500 text-red-500 hover:bg-red-50 active:bg-red-100',
    warning:
      'border-2 border-yellow-500 text-yellow-500 hover:bg-yellow-50 active:bg-yellow-100',
    success:
      'border-2 border-green-500 text-green-500 hover:bg-green-50 active:bg-green-100',
    info: 'border-2 border-blue-500 text-blue-500 hover:bg-blue-50 active:bg-blue-100',
    default:
      'border-2 border-gray-500 text-gray-500 hover:bg-gray-100 active:bg-gray-200',
  },
  text: {
    primary: 'text-primary hover:bg-primary/10 active:bg-primary/20',
    secondary: 'text-gray-500 hover:bg-gray-100 active:bg-gray-200',
    error: 'text-red-500 hover:bg-red-50 active:bg-red-100',
    warning: 'text-yellow-500 hover:bg-yellow-50 active:bg-yellow-100',
    success: 'text-green-500 hover:bg-green-50 active:bg-green-100',
    info: 'text-blue-500 hover:bg-blue-50 active:bg-blue-100',
    default: 'text-gray-500 hover:bg-gray-100 active:bg-gray-200',
  },
};

const sizeClasses = {
  small: 'px-3 py-2 text-sm',
  medium: 'px-4 py-3 text-base',
  large: 'px-5 py-4 text-lg',
};

const fontWeightClasses = {
  light: 'font-[300]',
  regular: 'font-[400]',
  medium: 'font-[500]',
  bold: 'font-[700]',
};

export const Button = ({
  children,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  fontWeight = 'regular',
  onClick,
  className,
  type = 'button',
  disabled = false,
  href = undefined,
  target = undefined,
  rel = undefined,
}: ButtonProps) => {
  const variantClass = variantClasses[variant][color];
  const sizeClass = sizeClasses[size];
  const weightClass = fontWeightClasses[fontWeight];

  const baseClasses = clsx(
    'inline-flex items-center justify-center',
    'rounded-lg transition-all duration-200',
    !disabled && variantClass,
    sizeClass,
    weightClass,
    disabled &&
      'bg-neutral-750 text-neutral-300 cursor-not-allowed pointer-events-none',
    className,
  );

  if (href) {
    return (
      <a
        href={disabled ? undefined : href}
        target={target}
        rel={rel || 'noreferrer'}
        className={baseClasses}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      className={baseClasses}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
