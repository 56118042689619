import { Pencil, Trash } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { get, patch, post, remove } from 'src/api/requests';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { Modal } from 'src/components/Modal';
import { SelectBox } from 'src/components/SelectBox';
import { SideDropActions } from 'src/components/SideDropActions';
import Table from 'src/components/Table';
import { useFirebaseAuth } from 'src/contexts/AuthContext/FirebaseAuthContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Role } from 'src/interfaces/role.interface';
import { User } from 'src/interfaces/user.interface';
import { isValidEmail } from 'src/utils/email';

const userHeaders = [
  { key: 'name', label: 'User', width: '40%' },
  { key: 'roles', label: 'Role', width: '20%' },
  { key: 'email', label: 'Email', width: '20%' },
  { key: 'actions', label: '', width: '20%', disableSorting: true },
];

interface UsersProps {
  envId: string;
}

export const Users = ({ envId }: UsersProps) => {
  const notification = useNotification();
  const auth = useFirebaseAuth();

  const [modalInviteUserOpen, setModalInviteUserOpen] = useState(false);
  const [emailInvite, setEmailInvite] = useState('');
  const [roleInvite, setRoleInvite] = useState('');

  const [modalEditRoleOpen, setModalEditRoleOpen] = useState(false);
  const [selectedEditRoleUser, setSelectedEditRoleUser] = useState<User | null>(
    null,
  );

  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [rolesSelect, setRolesSelect] = useState<
    { label: string; value: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [sizeUsers, setSizeUsers] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [, setUserSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);

  const [selectedRemoveUser, setSelectedRemoveUser] = useState<User | null>(
    null,
  );
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);

  const fetchUsers = async (page: number, perPage: number) => {
    setLoading(true);

    const query = new URLSearchParams({
      page: String(page),
      size: String(perPage),
    });

    const data = await get(`/users?${query.toString()}`, {
      envId,
    });

    setUsers(data);
    setTotalUsers(data.length);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(currentPage, sizeUsers);
  }, [currentPage]);

  const handleAddUser = () => {
    setModalInviteUserOpen(true);
  };

  const handleRemoveUser = (user: User) => {
    setSelectedRemoveUser(user);
    setShowRemoveUserModal(true);
  };

  const confirmDelete = async () => {
    setLoading(true);

    try {
      await remove(`/users/${selectedRemoveUser?.id}`, { envId });
      notification.success('User removed successfully');
      setShowRemoveUserModal(false);
      const updatedUsers = users.filter(
        (user) => user.id !== selectedRemoveUser?.id,
      );
      setUsers(updatedUsers);
    } catch {
      notification.error('Failed to remove user');
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    const data = await get('/roles', {
      envId,
    });
    setRolesSelect(
      data.map((role: Role) => ({ label: role.name, value: role.id })),
    );
    setRoles(data);
  };

  const inviteUser = async (email: string, roleId: string) => {
    setLoading(true);

    try {
      await post(
        '/users/invite',
        {
          email,
          roleId,
        },
        { envId },
      );

      notification.success('User invited successfully');
      setModalInviteUserOpen(false);
      await fetchUsers(currentPage, sizeUsers);
    } catch {
      notification.error('Failed to invite user');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateRole = (user: User) => {
    setSelectedEditRoleUser(user);
    setModalEditRoleOpen(true);
  };

  const updateUserRole = async (userId: string, roleId: string) => {
    setLoading(true);

    try {
      await patch(`/users/${userId}/role/${roleId}`, {}, { envId });
      notification.success('User role updated successfully');
      setModalEditRoleOpen(false);
      const updatedUsers = users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            roles: roles.filter((role) => role.id === roleId),
          };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch {
      notification.error('Failed to update user role');
    } finally {
      setLoading(false);
    }
  };

  const handleIsValidEmail = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.validity.valid || !isValidEmail(e.target.value)) {
      notification.error('Invalid email');
      setEmailInvite('');
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <div className="mb-6">
      <div className="flex justify-between mb-6">
        <h2 className="text-xl font-semibold text-control-plane-300 mb-4">
          Users on this Environment
        </h2>

        <Button variant="contained" onClick={handleAddUser}>
          Add User
        </Button>
      </div>

      <Table
        headers={userHeaders}
        rows={users.map((user) => ({
          ...user,
          name: (
            <>
              {user.fname} {user.lname}{' '}
              {auth?.user?.uid === user.authId && <b>(you)</b>}
            </>
          ),
          organization: user.organization.name,
          roles: user.roles.map((role) => role.name).join(', '),
          actions: (
            <>
              {auth?.user?.uid !== user.authId && (
                <div className="flex justify-center items-center">
                  <SideDropActions
                    options={[
                      {
                        label: 'Edit',
                        Icon: Pencil,
                        onClick: () => handleUpdateRole(user),
                      },
                      {
                        label: 'Delete',
                        Icon: Trash,
                        color: 'error',
                        onClick: () => handleRemoveUser(user),
                      },
                    ]}
                  />
                </div>
              )}
            </>
          ),
        }))}
        totalItems={totalUsers}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        loading={loading}
        setSize={setSizeUsers}
        defaultSize={sizeUsers}
        onSort={setUserSortConfig}
      />

      {/* Modal to remove user */}
      <Modal
        title={`Remove User`}
        isOpen={showRemoveUserModal}
        onClose={() => setShowRemoveUserModal(false)}
        actionButton={
          <Button onClick={() => confirmDelete()} disabled={loading}>
            Remove User
          </Button>
        }
      >
        <div>
          You are about to remove the user{' '}
          <b>
            {selectedRemoveUser?.fname} {selectedRemoveUser?.lname}
            {!selectedRemoveUser?.fname && !selectedRemoveUser?.lname
              ? selectedRemoveUser?.email
              : ''}
          </b>{' '}
          from this environment. Are you sure you want to proceed?
        </div>
      </Modal>

      {/* Modal to invite user */}
      <Modal
        title={`Invite User`}
        isOpen={modalInviteUserOpen}
        onClose={() => setModalInviteUserOpen(false)}
        actionButton={
          <Button
            onClick={() => inviteUser(emailInvite, roleInvite)}
            disabled={
              loading ||
              emailInvite.length === 0 ||
              roleInvite.length === 0 ||
              !isValidEmail(emailInvite)
            }
          >
            Invite User
          </Button>
        }
      >
        <div className="flex justify-end italic text-sm">* Required fields</div>

        <div className="grid grid-cols-1 gap-6">
          <div>
            <Input
              label="Email"
              type="email"
              value={emailInvite}
              onChange={(e) => setEmailInvite(e.target.value)}
              onBlur={handleIsValidEmail}
              required
            />
          </div>

          <div>
            <Label>Select Role</Label>
            <SelectBox
              key={roles.length}
              options={rolesSelect}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) => setRoleInvite(value)}
              defaultValue={rolesSelect.find(
                (role) => role.value === roleInvite,
              )}
              disabled={loading}
            />
          </div>
        </div>
      </Modal>

      {/* Modal to edit role */}
      <Modal
        title={`Edit Role - ${selectedEditRoleUser?.fname || ''} ${selectedEditRoleUser?.lname || ''} ${!selectedEditRoleUser?.fname && !selectedEditRoleUser?.lname ? selectedEditRoleUser?.email : ''}`}
        isOpen={modalEditRoleOpen}
        onClose={() => setModalEditRoleOpen(false)}
        actionButton={
          <Button
            onClick={() =>
              updateUserRole(
                selectedEditRoleUser?.id || '',
                selectedEditRoleUser?.roles[0]?.id || '',
              )
            }
            disabled={loading}
          >
            Update Role
          </Button>
        }
      >
        <div className="grid grid-cols-1 gap-6">
          <div>
            <Label>Select Role</Label>
            <SelectBox
              key={selectedEditRoleUser?.roles[0]?.id}
              options={rolesSelect}
              variant="outlined"
              color="primary"
              size="medium"
              onChange={(value) => setRoleInvite(value)}
              defaultValue={rolesSelect.find(
                (role) => role.value === selectedEditRoleUser?.roles[0]?.id,
              )}
              disabled={loading}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
