import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import {
  clearCachedToken,
  get,
  patch,
  post,
  renewToken,
} from 'src/api/requests';
import { Button } from 'src/components/Button';
import { ContentWrapper } from 'src/components/ContentWrapper';
import { Heading } from 'src/components/Heading';
import { Loading } from 'src/components/Loading';
import { Modal } from 'src/components/Modal';
import { Option, SelectBox } from 'src/components/SelectBox';
import {
  EnvironmentContext,
  useEnvironment,
} from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { EnvironmentPayload } from 'src/interfaces/environment.interface';
import {
  Organization,
  UserOrganization,
} from 'src/interfaces/organization.interface';
import { ApiKeys } from './components/ApiKeys';
import { Organizations } from './components/Organizations';
import { Users } from './components/Users';
import { getPayloadJWT, isJWT } from 'src/utils/jwt';
import { Input } from 'src/components/Input';
import { Label } from 'src/components/Label';
import { useSearchParams } from 'react-router-dom';

export const Settings = () => {
  const notification = useNotification();
  const {
    environment,
    fetchEnvironments,
    environmentList,
    updateEnvironment,
    setEnvironmentList,
  } = useEnvironment();
  const envId = environment?.envId;
  const auth = getAuth();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [newEnvironment, setNewEnvironment] = useState<EnvironmentPayload>(
    {} as EnvironmentPayload,
  );
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [currentOrg, setCurrentOrg] = useState<Option | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>(undefined);

  const handleCreateEnvironment = () => {
    setModalOpen(true);
  };

  const createEnvironment = async () => {
    setLoading(true);

    try {
      await post(`/environments`, newEnvironment, { envId });
      await fetchEnvironments();

      notification.success('Environment created successfully');
      setModalOpen(false);
    } catch {
      notification.error('Failed to create environment');
    } finally {
      setLoading(false);
      setNewEnvironment({ name: '' } as EnvironmentPayload);
    }
  };

  const [switchOrgModalOpen, setSwitchOrgModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<
    string | null
  >(null);

  useEffect(() => {
    const showChangeOrganization = searchParams.get('showChangeOrganization');
    if (showChangeOrganization === 'true') {
      setSwitchOrgModalOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (!userId) return;

      setLoading(true);
      const organizations: UserOrganization[] = await get(
        `/users/${userId}/organizations`,
        {
          envId,
        },
      );
      setOrganizations(organizations.map((org) => org.organization));
      setLoading(false);
    };
    fetchOrganizations();
  }, [userId]);

  const handleSwitchOrganization = () => {
    setSwitchOrgModalOpen(true);
  };

  const confirmOrganizationSwitch = async () => {
    if (selectedOrganization && auth.currentUser?.uid) {
      setLoading(true);
      await patch(`/users/${userId}/organizations/${selectedOrganization}`, {
        envId,
      });

      setSwitchOrgModalOpen(false);
      setSelectedOrganization(null);
      setCurrentOrg({
        label: organizations.find((org) => org.id === selectedOrganization)
          ?.name,
        value: selectedOrganization,
      });

      await post(`/users/sync`, {});

      clearCachedToken();

      await renewToken();

      const fetchedEnvironments = await get('/environments');

      setEnvironmentList(fetchedEnvironments);
      updateEnvironment({
        userId: auth.currentUser.uid,
        envId: fetchedEnvironments[0].id,
      } as EnvironmentContext);

      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchCurrentUserAndOrganization() {
      const token = await auth.currentUser?.getIdToken();

      if (token && isJWT(token)) {
        const decoded = getPayloadJWT(token);

        if (decoded.id) {
          setUserId(decoded.id);
        }

        if (decoded.organization) {
          setCurrentOrg({
            label: decoded.organization?.name || '',
            value: decoded.organization?.id || '',
          });
        }
      }
    }

    fetchCurrentUserAndOrganization();
  }, [userId]);

  const handleCloseCreateEnvironmentModal = () => {
    setModalOpen(false);
    setNewEnvironment({ name: '' } as EnvironmentPayload);
  };

  return (
    <div className="flex-1">
      <Heading title="Settings" subtitle="Manage your organization settings">
        <div className="flex flex-row gap-2">
          <Button
            className="mt-6 w-60"
            disabled={loading}
            onClick={handleCreateEnvironment}
          >
            Create Environment
          </Button>

          <Button
            className="mt-6 w-60"
            disabled={loading}
            onClick={handleSwitchOrganization}
          >
            Switch Organization
          </Button>
        </div>
      </Heading>

      {loading && <Loading className="p-4" />}

      {!loading && (
        <ContentWrapper>
          <ApiKeys
            envId={String(envId)}
            fetchEnvironments={fetchEnvironments}
            environmentList={environmentList}
          />
          <Organizations
            organizations={organizations}
            setOrganizations={setOrganizations}
            currentOrg={currentOrg}
            setCurrentOrg={setCurrentOrg}
          />
          <Users envId={String(envId)} />
        </ContentWrapper>
      )}

      <Modal
        title={`Create Environment`}
        isOpen={modalOpen}
        onClose={() => handleCloseCreateEnvironmentModal()}
        actionButton={
          <Button
            onClick={createEnvironment}
            disabled={loading || !newEnvironment || !newEnvironment.name}
          >
            Create Environment
          </Button>
        }
      >
        <div className="flex justify-end italic text-sm">* Required fields</div>

        <div className="grid grid-cols-1 gap-6">
          <div>
            <Input
              label="Name"
              type="text"
              value={newEnvironment.name}
              onChange={(e) =>
                setNewEnvironment({ ...newEnvironment, name: e.target.value })
              }
              required
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="Switch Organization"
        isOpen={switchOrgModalOpen}
        onClose={() => setSwitchOrgModalOpen(false)}
        actionButton={
          <Button
            onClick={confirmOrganizationSwitch}
            disabled={
              loading ||
              !selectedOrganization ||
              selectedOrganization === currentOrg?.value
            }
          >
            Switch
          </Button>
        }
        className="w-96"
      >
        <Label required>Select Organization</Label>
        <SelectBox
          key={currentOrg?.value + '_' + currentOrg?.label}
          options={organizations.map((org) => ({
            label: org.name,
            value: org.id,
          }))}
          disabled={loading}
          onChange={(value) => setSelectedOrganization(value)}
          defaultValue={currentOrg}
        />
      </Modal>
    </div>
  );
};
