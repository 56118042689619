import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get, patch, post } from 'src/api/requests';
import { StepManager } from 'src/components/StepManager';
import { useEnvironment } from 'src/contexts/EnvironmentContext';
import { useNotification } from 'src/contexts/NotificationContext';
import { Agent } from 'src/interfaces/agent.interface';
import { Prompt } from 'src/interfaces/prompt.interface';
import { Voice } from 'src/interfaces/voice.interface';
import { AgentStub } from 'src/stubs/agent.stub';
import { AgentSingleStep } from './components/AgentSingleStep';
import { Webhook } from 'src/interfaces/webhook.interface';
import { Action } from 'src/interfaces/action.interface';

type AgentProps = {
  editing?: boolean;
};

export const ManageAgent = ({ editing = false }: AgentProps) => {
  const { environment } = useEnvironment();
  const envId = environment?.envId;

  const { id: editId } = useParams();

  const [agent, setAgent] = useState<Agent>(AgentStub as Agent);
  const [agentLoading, setAgentLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [attachedVoice, setAttachedVoice] = useState<Voice>();
  const [attachedPrompt, setAttachedPrompt] = useState<Prompt>();
  const [attachedWebhook, setAttachedWebhook] = useState<Webhook>();
  const [attachedActions, setAttachedActions] = useState<Action[]>([]);
  const [actions, setActions] = useState<Action[]>([]);

  const navigate = useNavigate();
  const notification = useNotification();

  const fetchActions = async () => {
    setActionLoading(true);
    const { items } = await get('/actions', { envId });
    setActions(items);
    setActionLoading(false);
  };

  const saveAgent = async () => {
    const url = editing ? `/agents/${editId}` : '/agents';

    const { actions, webhook, ...restAgent } = agent;

    const clonedAgent = {
      ...restAgent,
      ...(typeof webhook === 'string' && webhook.length && { webhook }),
      ...(typeof webhook !== 'string' &&
        !!webhook &&
        webhook.id && { webhook }),
      ...(!!actions && { actions }),
      label: agent.name || agent.label,
    };

    setAgentLoading(true);
    if (editing) {
      await patch(url, clonedAgent, { envId });
    } else {
      await post(url, clonedAgent, { envId });
    }

    notification.success(
      `Agent ${editing ? 'updated' : 'created'} successfully`,
    );
    navigate('/agents');
    setAgentLoading(false);
  };

  useEffect(() => {
    fetchActions();
  }, [envId]);

  useEffect(() => {
    if (editId) {
      setAgentLoading(true);
      get(`/agents/${editId}`, { envId }).then((data) => {
        setAgent({
          ...data,
          llm_fallback: data.llm_fallback || AgentStub.llm_fallback,
          prompt: data.prompt?.id || '',
          voice: data.voice?.id || '',
          webhook: data.webhook?.id || '',
          actions: data.actions.map((action: Action) => action.id),
        });
        setAttachedActions(data.actions);
        setAttachedVoice(data.voice);
        setAttachedPrompt(data.prompt);
        setAttachedWebhook(data.webhook);
        setAgentLoading(false);
      });
    }
  }, [editId, envId]);

  useEffect(() => {
    setDisabled(
      !agent.voice ||
        !agent.prompt ||
        String(agent.prompt) === '' ||
        (agent.prompt as Prompt).content === '',
    );
  }, [agent]);

  const stepsContent = [
    {
      title: 'Configure Your Agent',
      component: (
        <AgentSingleStep
          agent={agent}
          setAgent={setAgent}
          loading={actionLoading || agentLoading}
          defaultVoice={attachedVoice}
          defaultPrompt={attachedPrompt}
          defaultWebhook={attachedWebhook}
          defaultActions={attachedActions}
          actions={actions}
        />
      ),
    },
  ];

  return (
    <>
      <StepManager
        title={editing ? 'Edit Agent' : 'New Agent'}
        stepsContent={stepsContent}
        finishAction={disabled ? undefined : saveAgent}
        loading={actionLoading || agentLoading}
      />
    </>
  );
};
